import React, { useState,useEffect } from "react";
import Sidebar from "./Components/Sidebar";
import Layout from "./Components/Layout";
// import DataTable from "react-data-table-component";
import DataTable from "./Components/DataTable";
import "./index.css";
import ServiceTable from "./Components/AdminServiceComponent/ServiceTable";
import ServiceForm from "./Components/AdminServiceComponent/ServiceForm";
import { useQuery, gql } from "@apollo/client";


const SERVICES_LIST=gql`
query{
  getAdminService{
    services{
      id
      name
      price
      description
      time
      isFeatured
      imageURL
      gender
      product{
        id
        name
      }
      serviceCategory{
        id
        name
      }
      serviceChild{
        id
        price
        serviceInventory{
          id
          name
        }
      }
    }
  }
}`;

const tempData = [
  {
    serviceName: "Hair Cut",
    category: "men",
    subCategory: "hair",
    price: 200,
    image: "img",
    inventory: [
      { item: "Hair Cutting kit", amount: 10 },
      { item: "Hair Colour kit", amount: 10 },
    ],
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur cupiditate saepe distinctio qui.",
  },
  {
    serviceName: "Hair Colouring",
    category: "hairs",
    subCategory: "hair",
    price: 400,
    image: "img",
    inventory: [{ item: "Hair Cutting kit", amount: 10 }],
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur cupiditate saepe distinctio qui.",
  },
  {
    serviceName: "Beard Styling",
    category: "Men",
    subCategory: "beard",
    price: 200,
    image: "img",
    inventory: [{ item: "Hair Cutting kit", amount: 10 }],
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur cupiditate saepe distinctio qui.",
  },
  {
    serviceName: "Hair Styling",
    category: "women",
    subCategory: "beard",
    price: 350,
    image: "img",
    inventory: [{ item: "Hair Cutting kit", amount: 10 }],
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur cupiditate saepe distinctio qui.",
  },
  {
    serviceName: "Beauty Spa",
    category: "women",
    subCategory: "spa",
    price: 400,
    image: "img",
    inventory: [{ item: "Hair Cutting kit", amount: 10 }],
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur cupiditate saepe distinctio qui.",
  },
  {
    serviceName: "Nails",
    category: "hairs",
    subCategory: "nails",
    price: 250,
    image: "img",
    inventory: [{ item: "Hair Cutting kit", amount: 10 }],
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur cupiditate saepe distinctio qui.",
  },
];

const AdminService = () => {
  const { loading, error, data } = useQuery(SERVICES_LIST);
  const [dataList, setData] = useState(tempData);
  const [openServiceForm, setOpenServiceForm] = useState(false);
  const [serviceList, setServiceList] = useState(tempData);
  const [isFetched, setIsFetched] = useState(false);
  useEffect(()=>{
    try{
      console.log('serviceList is');
    console.log(data);
    setServiceList(data.getAdminService.services)
    setIsFetched(true);
    }catch(err){
      console.log(err);
    }
    
  },[data])
  return (
    <>
     {
       isFetched ?
       (
        <div className=" h-screen flex">
        <Sidebar />
        <Layout>
          {openServiceForm ? (
            <ServiceForm setOpenServiceForm={setOpenServiceForm} />
          ) : (
            <ServiceTable 
            data={dataList} 
            serviceList={serviceList}
            setOpenServiceForm={setOpenServiceForm} />
          )}
        </Layout>
      </div>
       )
       :(
        <div className=" h-screen flex">
          <Sidebar />
        </div>
       )
     }
    </>
  );
};

export default AdminService;
