import React, { useState, useRef,useEffect } from "react";
import { IoMdCloudUpload } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import "../../index.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import axios from "axios";

const GET_INVENTORY_LIST= gql`
query{
  getInventory{
    status
    message
    inventoryList{
      id
      name
      canDecreased
  }
  }
}`

const UPDATE_EMPLOYEE = gql`
mutation updateAdminEmployee($data:UpdateEmployeeInput!){
  updateAdminEmployee(data:$data){
    status
    message
  }
}`;

const EditEmployeeForm = ({ setEditForm, editData }) => {
  // const [name, setName] = useState("");
  // const [mobileNumber, setMobileNumber] = useState("");
  // const [inventoryList, setInventoryList] = useState([
  //   { inventory: "", amount: "" },
  // ]);
  console.log('edit form data');
  console.log(editData);

  const [fullName, setName] = useState(editData.fullName);
  const [contactNumber, setMobileNumber] = useState(editData.contactNumber);
  // const [inventoryList, setInventoryList] = useState(editData.employeechild);
  const [employeeInventoryList, setEmployeeInventoryList] = useState(editData.employeechild);
  const [updateAdminEmployee, { addLoading, addError }] = useMutation(UPDATE_EMPLOYEE);
  const [inventoryList, setInventoryList] = useState([]);
  // const [govtIdImage, setGovtIdImage] = useState("");
  const [govtIdImage, setGovtIdImage] = useState(editData.governmentId);
  const [vehicleIdImage, setVehicleIdImage] = useState(editData.vehicleId);
  const [LicenseImage, setLicenseImage] = useState(editData.license);
  const [assignedGender, setAssignedGender] = useState(editData.gender);
  const govtId = useRef(null);
  const vehicleId = useRef(null);
  const license = useRef(null);
  const [baseLat, setBaseLat] = useState(editData.baseLat);
  const [baseLong, setBaseLong] = useState(editData.baseLong);

  const [dataList, setDataList] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const { loading, error, data } = useQuery(GET_INVENTORY_LIST);


  useEffect(()=>{
    try{
      console.log('error is');
    console.log(error);
    console.log('inventory list is');
    console.log(data.getInventory.inventoryList);
    
    setDataList(data.getInventory.inventoryList);

    let inventoryInfo = [];
   let result= editData.employeechild.map((item)=>{
     console.log('item is');
     console.log(item);
     inventoryInfo.push({ inventoryId: item.employeeInventory.id, price: item.price })
   });
   setInventoryList(inventoryInfo);
    setIsFetched(true);
    }catch(err){
      console.log(err);
    }
    
  },[data])

  // const handleGovtIdUpload = (event) => {
  //   const file = event.target.files[0];
  //   setGovtIdImage(file);
  // };

  // const handleBrowseGovtIdClick = () => {
  //   govtId.current.click();
  // };

  const handleAdd = (e) => {
    console.log('new list');
    e.preventDefault();
    let finalDataObj={};
    finalDataObj.id= editData.id;
    finalDataObj.fullName= fullName;
    finalDataObj.contactNumber= contactNumber;
    finalDataObj.inventoryList= inventoryList;
    finalDataObj.governmentId= govtIdImage;
    finalDataObj.vehicleId= vehicleIdImage;
    finalDataObj.license= LicenseImage;
    finalDataObj.gender = assignedGender;
    finalDataObj.baseLat= baseLat;
    finalDataObj.baseLong= baseLong;
    console.log(finalDataObj);
    // setEditForm(false);
    updateAdminEmployee({ 
      variables:{  
        data: finalDataObj
      }
    }).then((res)=>{
      console.log('res is');
      console.log(res);
      if(res.data.updateAdminEmployee.status === "SUCCESS"){
        window.location.reload();
      }
    }).catch(err=>{
      console.log(err);
    })
  };

  const handleInventoryChange = (index, event) => {
    const { name, value } = event.target;
    const updatedList = [...inventoryList];
    updatedList[index][name] = value;
    setInventoryList(updatedList);
  };

  const handleInventoryPriceChange = (index, event) => {
    const { name, value } = event.target;
    const updatedList = [...inventoryList];
    updatedList[index][name] = parseInt(value);
    setInventoryList(updatedList);
  };

  const handleRemoveRow = (index) => {
    const updatedList = [...inventoryList];
    updatedList.splice(index, 1);
    setInventoryList(updatedList);
  };

  const handleAddRow = () => {
    // setInventoryList([...inventoryList, { inventoryId: "", price: "" }]);
    setInventoryList([...inventoryList, { inventoryId: "", price: "" }]);
  };

  const handleGovtIdUpload = async(e) => {
    const file = e.target.files[0];
    // console.log(file.file);
    // setGovtIdImage(file);
   e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === 'Success'){
          setGovtIdImage(data.url);
        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  const handleVehicleIdUpload =async (e) => {
    const file = e.target.files[0];
    // setVehicleIdImage(file);
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === 'Success'){
          setVehicleIdImage(data.url);
        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  const handleLicenseUpload = async(e) => {
    const file = e.target.files[0];
    // setLicenseImage(file);
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === 'Success'){
          setLicenseImage(data.url);
        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  

  const handleBrowseGovtIdClick = () => {
    govtId.current.click();
  };

  const handleBrowseVehicleIdClick = () => {
    vehicleId.current.click();
  };

  const handleBrowseLicenseClick = () => {
    license.current.click();
  };
  return (
    // <div>lefhwekjh</div>
    <div>
    {
      isFetched &&
      (
        <div className="flex flex-col space-y-10">
      <h3 className="font-be-jost-pro font-bold text-2xl text-[#FBB033]">
        Add Employee
      </h3>

      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
          Name:
        </span>
        <input
          type="text"
          placeholder="Name*"
          className="px-2 py-1 bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none text-white w-5/6"
          value={fullName}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
          Mobile Number:
        </span>
        <div className="flex items-end w-full px-4">
          <span className="py-1 border-b-2 border-b-[#E0E0E0] text-[#8aa3af]">
            +91
          </span>
          <input
            type="text"
            placeholder="Mobile Number*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none text-white w-full"
            value={contactNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
          Inventory:
        </span>
        <div className="flex flex-col space-y-2 lg:space-y-8">
          {inventoryList.map((inventory, index) => {
            return (
              <div className="flex space-x-2 lg:space-x-8" key={index}>
                <select
                  className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
                    inventory.inventory === ""
                      ? " text-[#9A9A9A]"
                      : "text-white"
                  } w-1/2 lg:w-[25%]`}
                  value={inventory.inventoryId}
                  onChange={(e) => handleInventoryChange(index, e)}
                  name="inventoryId"
                >
                  <option value="" >
                    select Inventory
                  </option>
                  {/* <option value="Hair Cutting Kit">Hair Cutting Kit</option>
                  <option value="Hair Colour Kit">Hair Colour Kit</option>
                  <option value="Beard Kit">Beard Kit</option> */}
                  {
            dataList.map((item,i)=>(
              <option value={item.id} key={i}>{item.name}</option>
            ))
          }
                </select>
                <div className="flex items-center">
                  <span
                    className="text-[#FBB033] font-be-jost-pro text-base font-normal cursor-pointer"
                    onClick={handleAddRow}
                  >
                    {`Add Inventory +  `}
                  </span>
                  {inventoryList.length !== 1 && (
                    <AiOutlineDelete
                      className="text-[#FBB033] text-2xl cursor-pointer"
                      onClick={() => handleRemoveRow(index)}
                    />
                  )}
                </div>
                <input
                  type="text"
                  placeholder="Amount*"
                  className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
                  value={inventory.price}
                  onChange={(event) => handleInventoryPriceChange(index, event)}
                  name="price"
                />
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
          Government Id:
        </span>
        <div className="flex items-center w-full lg:w-1/2">
          <input
            id="govt-id"
            type="file"
            ref={govtId}
            className="hidden"
            onChange={handleGovtIdUpload}
          />
          <input
            type="text"
            placeholder="Government Id*"
            disabled={true}
            className="w-1/2 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          />
          {
            editData.governmentId &&
            (
            <a className="ml-3 mr-2 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer" target="_blank" href={`https://api.barbera.in${editData.governmentId}`}>
            View
          </a>
            )
          }
          <IoMdCloudUpload
          className="text-[#FBB033] text-3xl"
          onClick={handleBrowseGovtIdClick}
        />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
      <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
        gender:
        </span>
      <select
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-4/6`}
          value={assignedGender}
          onChange={(e) => setAssignedGender(e.target.value)}
        >
          <option value="" disabled hidden>
            Gender*
          </option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
</div>
<div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
        Latitude:
        </span>
        <input
            type="text"
            placeholder="Lat*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
            value={baseLat}
            onChange={(e) => setBaseLat(e.target.value)}
            name="Lat"
          />
      </div>
      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
        Longitude:
        </span>
        <input
            type="text"
            placeholder="Long*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
            value={baseLong}
            onChange={(e) => setBaseLong(e.target.value)}
            name="Lat"
          />
      </div>
{/* <div className="flex space-x-2 lg:space-x-8" >
<span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
        Latitude:
        </span>
        <input
            type="text"
            placeholder="Lat*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
            value={baseLat}
            onChange={(e) => setBaseLat(e.target.value)}
            name="Lat"
          />
         <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
        Longitude:
        </span>
         <input
            type="text"
            placeholder="Long*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
            value={baseLong}
            onChange={(e) => setBaseLong(e.target.value)}
            name="Lat"
          />
        </div> */}
      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
        License:
        </span>
        <div className="flex items-center w-full lg:w-1/2">
          <input
            id="govt-id"
            type="file"
            ref={license}
            className="hidden"
            onChange={handleLicenseUpload}
          />
          <input
            type="text"
            placeholder="License*"
            disabled={true}
            className="w-1/2 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          />
          {
            editData.license &&
            (
            <a className="ml-3 mr-2 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer" target="_blank" href={`https://api.barbera.in${editData.license}`}>
            View
          </a>
            )
          }
          <IoMdCloudUpload
        className="text-[#FBB033] text-3xl"
        onClick={handleBrowseLicenseClick}
      />
        </div>
      </div>
      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 items-center lg:items-end w-5/6">
        <span className="font-be-jost-pro font-medium text-white text-sm w-[15%]">
        Vehicle Id:
        </span>
        <div className="flex items-center w-full lg:w-1/2">
          <input
            id="govt-id"
            type="file"
            ref={vehicleId}
            className="hidden"
            onChange={handleVehicleIdUpload}
          />
          <input
            type="text"
            placeholder="Vehicle Id*"
            disabled={true}
            className="w-1/2 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          />
          {
            editData.vehicleId &&
            (
            <a className="ml-3 mr-2 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer" target="_blank" href={`https://api.barbera.in${editData.vehicleId}`}>
            View
          </a>
            )
          }
          <IoMdCloudUpload
          className="text-[#FBB033] text-3xl"
          onClick={handleBrowseVehicleIdClick}
        />
        </div>
        
      </div>
      

      <div className="flex py-16 w-full justify-center items-center">
        <button
          className="px-5 py-2 bg-[#FBB034] font-poppins text-sm font-bold rounded-md"
          onClick={handleAdd}
        >
          Add Employee
        </button>
      </div>
    </div>
      )
    }
    </div>
  );
};

export default EditEmployeeForm;
