import React from "react";
import "../../index.css";
// import { ReactComponent as Icon } from "./bag-2.svg";

const BrokenBag = ({ fill }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={fill}>
      <path
        d="M16.6203 6.474H16.4136C16.3872 4.003 14.4242 2 12.0117 2C10.7967 2.017 9.723 2.46 8.88729 3.311C8.06231 4.149 7.60398 5.265 7.58643 6.474H7.3797C4.68923 6.474 2.5 8.719 2.5 11.479V16.995C2.5 19.755 4.68923 22 7.3797 22C7.78341 22 8.11107 21.664 8.11107 21.25C8.11107 20.836 7.78341 20.5 7.3797 20.5C5.49569 20.5 3.96274 18.928 3.96274 16.995V11.479C3.96274 9.546 5.49569 7.974 7.3797 7.974H7.58155V9.555C7.58155 9.969 7.90921 10.305 8.31292 10.305C8.71664 10.305 9.04429 9.969 9.04429 9.555V6.509C9.04722 5.702 9.35732 4.944 9.91608 4.375C10.4768 3.808 11.2247 3.545 12.0088 3.5C13.6168 3.5 14.9245 4.83 14.9508 6.474H11.7513C11.3476 6.474 11.02 6.81 11.02 7.224C11.02 7.638 11.3476 7.974 11.7513 7.974H14.9557V9.555C14.9557 9.969 15.2834 10.305 15.6871 10.305C16.0908 10.305 16.4184 9.969 16.4184 9.555V7.974H16.6203C18.5043 7.974 20.0373 9.546 20.0373 11.479V16.995C20.0373 18.928 18.5043 20.5 16.6203 20.5H11.3632C10.9595 20.5 10.6319 20.836 10.6319 21.25C10.6319 21.664 10.9595 22 11.3632 22H16.6203C19.3108 22 21.5 19.755 21.5 16.995V11.479C21.5 8.719 19.3108 6.474 16.6203 6.474Z"
        // fill="#FBB033"
      />
    </svg>
  );
};

export default BrokenBag;
