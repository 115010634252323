import React, { useState, useRef,useEffect } from "react";
import { IoMdCloudUpload } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import "../../index.css";
import { useQuery, gql, useMutation } from "@apollo/client";
import axios from "axios";

const GET_INVENTORY_LIST= gql`
query{
  getInventory{
    status
    message
    inventoryList{
      id
      name
      canDecreased
  }
  }
}`

const GET_CATEGORY= gql`
query{
  getCategory{
    status
    message
    categoryList{
      id
      name
  }
  }
}`

const ADD_SERVICE = gql`
mutation addService($data:serviceInput!){
  addService(data:$data){
    status
    message
  }
}`;

const GET_PRODUCT= gql`
query($data:ID!){
  getProductByCategoryId(data:$data){
    status
    message
    products{
      id
      name
    }
  }
}
`

const ServiceForm = ({ setOpenServiceForm }) => {
  
  const { data: otherData, loading: otherLoading, error: otherError }  = useQuery(GET_CATEGORY);
  const { data: productData, loading: productLoading, error: productError, refetch }  = useQuery(GET_PRODUCT);
  
  const { loading, error, data } = useQuery(GET_INVENTORY_LIST);

  const [addService, { addLoading, addError }] = useMutation(ADD_SERVICE);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [time, setTime] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  
  const [image, setImage] = useState(null);
  const [inventoryList, setInventoryList] = useState([
    { inventoryId: "", price: "" },
  ]);
  const [dataList, setDataList] = useState([]);
  const [dataCategoryList, setDataCategoryList] = useState([]);
  const [productList, setProductList] = useState("");
  const [isFetched, setIsFetched] = useState(false);
  const [description, SetDesciption] = useState("");
  const [gender,setGender]= useState("");
  const fileInputRef = useRef(null);

  useEffect(()=>{
    try{
      console.log('product data is');
      // refetch({data: "8d871f8c-d2ef-4786-a19a-2b289c46d53d"}).then((res)=>{
      //   console.log('onCategoryChange hitted');
      //   console.log(res);
      //   // if(res.data.addEmployee.status === "SUCCESS"){
      //   //   window.location.reload();
      //   // }
      // }).catch(err=>{
      //   console.log(err);
      // })
      console.log('category List is is');
    console.log(otherData);
    
    setDataCategoryList(otherData.getCategory.categoryList);
    setDataList(data.getInventory.inventoryList);
    setIsFetched(true);
    }catch(err){
      console.log(err);
    }
    
  },[data])

  const handleImageUpload = async(e) => {
    const file = e.target.files[0];
    // setLicenseImage(file);
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === 'Success'){
          setImage(data.url);
          // setLicenseImage(data.url);
        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
    
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleInventoryChange = (index, event) => {
    const { name, value } = event.target;
    const updatedList = [...inventoryList];
    updatedList[index][name] = value;
    setInventoryList(updatedList);
  };

  const handleInventoryPriceChange = (index, event) => {
    const { name, value } = event.target;
    const updatedList = [...inventoryList];
    updatedList[index][name] =parseInt(value);
    setInventoryList(updatedList);
  };

  const handleRemoveRow = (index) => {
    const updatedList = [...inventoryList];
    updatedList.splice(index, 1);
    setInventoryList(updatedList);
  };

  const handleAddRow = () => {
    setInventoryList([...inventoryList, { inventoryId: "", price: "" }]);
  };

  const onCategoryChange =(e)=>{
    setCategory(e.target.value);
    console.log(e.target.value);
    refetch({data: e.target.value}).then((res)=>{
      console.log('onCategoryChange hitted');
      console.log(res);
      if(res.data.getProductByCategoryId.status === "SUCCESS"){
      //   window.location.reload();
      setProductList(res.data.getProductByCategoryId.products);
      }
    }).catch(err=>{
      console.log(err);
    })
  }
  const handleSubmit = (e) => {
    // setOpenServiceForm(false);
    // console.log(name, price, category, subCategory, inventoryList, description);
    e.preventDefault();
    let finalDataObj={};
    finalDataObj.name= name;
    finalDataObj.imageURL= image;
    finalDataObj.price= parseInt(price);
    finalDataObj.productId= subCategory;
    finalDataObj.description= description;
    finalDataObj.inventoryList= inventoryList;
    finalDataObj.categoryId= category;
    finalDataObj.productId = subCategory;
    finalDataObj.time =parseInt(time);
    finalDataObj.gender= gender;
    console.log(finalDataObj);
    addService({ 
      variables:{  
        data: finalDataObj
      }
    }).then((res)=>{
      console.log('add service res is');
      console.log(res);
      if(res.data.addService.status === "SUCCESS"){
        window.location.reload();
      }
    }).catch(err=>{
      console.log(err);
    })
  };

  const subCategoryClass = subCategory ? "text-white" : " text-[#9A9A9A]";
  const categoryClass = category ? "text-white" : " text-[#9A9A9A]";

  return (
    <div>
      {
        isFetched &&
        (
          <div className="flex flex-col py-3 px-2 space-y-5">
      <h3 className="text-[#FBB033] font-be-jost-pro font-bold text-2xl">
        Add Service
      </h3>
      <div className="w-full flex flex-col space-y-8">
        <input
          type="text"
          placeholder="Name*"
          className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Price*"
          className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <input
          type="text"
          placeholder="Time*"
          className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          value={time}
          onChange={(e) => setTime(e.target.value)}
        />
        <select
          placeholder="Category*"
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${categoryClass}`}
          value={category}
          // onChange={(e) => setCategory(e.target.value)}
          onChange={onCategoryChange}
        >
          <option value="" disabled hidden>
            Category*
          </option>
          {/* <option value="men">Men</option>
          <option value="women">Women</option> */}
          {
            dataCategoryList.map((item,i)=>(
              <option value={item.id}>{item.name}</option>
            ))
          }
        </select>
        <select
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${subCategoryClass}`}
          value={subCategory}
          onChange={(e) => setSubCategory(e.target.value)}
        >
          <option value="" disabled selected hidden>
            Sub-Category*
          </option>
          {/* <option value="hair">hair</option>
          <option value="beard">beard</option>
          <option value="spa">spa</option>
          <option value="nail">nail</option> */}
          {
            productList.length>0 &&
            (
              productList.map((item,i)=>(
                <option value={item.id}>{item.name}</option>
              ))
            )
            
          }
        </select>
        <div className="flex items-center">
          <input
            id="image-upload"
            type="file"
            ref={fileInputRef}
            className="hidden"
            onChange={handleImageUpload}
          />
          <input
            type="text"
            placeholder="Upload Image*"
            disabled={true}
            className="w-4/5 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          />
          <IoMdCloudUpload
            className="text-[#9A9A9A] text-3xl"
            onClick={handleBrowseClick}
          />
          <p className="ml-3 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer">
            Mark as Primary
          </p>
        </div>
        <select
          placeholder="Category*"
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${categoryClass}`}
          value={gender}
          // onChange={(e) => setCategory(e.target.value)}
          onChange={(e)=>setGender(e.target.value)}
        >
          <option value="" disabled hidden>
            Gender*
          </option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        {/* <div className="w-full"> */}
        {inventoryList.map((inventory, index) => {
          return (
            <div className="flex space-x-2 lg:space-x-8" key={index}>
              <select
                className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
                  inventory.inventory === "" ? " text-[#9A9A9A]" : "text-white"
                } w-1/2 lg:w-[25%]`}
                value={inventory.inventoryId}
                onChange={(e) => handleInventoryChange(index, e)}
                name="inventoryId"
              >
                <option value="" disabled hidden>
                  Inventory*
                </option>
                {/* <option value="Hair Cutting Kit">Hair Cutting Kit</option>
                <option value="Hair Color Kit">Hair Color Kit</option> */}
                {
                  dataList.map((item,i)=>(
                    <option value={item.id} key={i}>{item.name}</option>
                  ))
                }
              </select>
              <div className="flex items-center">
                <span
                  className="text-[#FBB033] font-be-jost-pro text-base font-normal cursor-pointer"
                  onClick={handleAddRow}
                >
                  {`Add Inventory +  `}
                </span>
                {inventoryList.length !== 1 && (
                  <AiOutlineDelete
                    className="text-[#FBB033] text-2xl cursor-pointer"
                    onClick={() => handleRemoveRow(index)}
                  />
                )}
              </div>
              <input
                type="number"
                placeholder="Amount*"
                className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
                value={inventory.price}
                onChange={(event) => handleInventoryPriceChange(index, event)}
                name="price"
              />
            </div>
          );
        })}

        <input
          type="text"
          placeholder="Description*"
          className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
          value={description}
          onChange={(e) => SetDesciption(e.target.value)}
        />
        <div className="flex items-center justify-center">
          <button
            className="bg-[#FBB034] text-[#202125] py-2 px-6 rounded-md font-poppins text-sm font-bold"
            onClick={handleSubmit}
          >
            Add Service
          </button>
        </div>
        {/* </div> */}
      </div>
    </div>
        )
      }
    </div>
  );
};

export default ServiceForm;
