import React from "react";
import Card from "./Components/Card";
import Menu from "./Components/Menu";

const Services = () => {
  return (
    <div className="w-full h-auto">
      <div className="flex flex-col items-center md:justify-around md:items-center w-full min-h-[60] py-16 bg-[#11120F] space-y-7">
        <div className="flex flex-col items-center justify-end  space-y-3 w-full px-2 md:px-4 md:w-[60%]">
          <h3 className="font-be-jost-pro text-3xl font-semibold text-white">
            Popular <span className="text-[#FBB034]">Services</span>
          </h3>
          <p className="text-[#9A9A9A] font-be-jost-pro text-xl font-normal text-center">
          We are an end-to-end provider of salon and unisex grooming services in India which is why our range of salon services is quite comprehensive!
          </p>
        </div>
        <div className="flex justify-center items-center flex-wrap">
          <Card title={"Hair Cut"} description={"The modern man and woman have to look their best and we help you get that look. Avail our bespoke hair cut services at the best rates!"}/>
          <Card title={"Stylish Shave"} description={"Planning to let go of that macho-looking beard for a while? Why not call us up and avail our stylish shaving services?"}/>
          <Card title={"Hair Color"} description={"Are you planning on altering the look of your hair by applying the best quality hair colouring products? Leave it to our professionals and reap the benefits!"}/>
          <Card title={"Cleaning Facial"} description={"Does your facial skin feel look a bit dull every day!? You might want to try our cleaning facial services. Get quality assured cleaning facial services at the best rates!"}/>
          <Card title={"Waxing"} description={"Experience the smoothness of waxing, revealing your radiant skin and boosting your confidence."}/>
          <Card title={"Hair Care"} description={"Discover the secrets to luxurious hair care with our natural, sulfate-free products that strengthen, hydrate, and revitalize your locks. Say goodbye to dullness and hello to gorgeous, salon-worthy hair every day."}/>
          <Card title={"Makeups"} description={"Enhance your beauty with our high-quality makeup collection, designed to highlight your best features and create stunning looks for any occasion."}/>
          <Card title={"Hairstyling"} description={"Unlock your hairstyle potential with our expert hairstyling products, providing effortless control and long-lasting hold for the perfect look every time."}/>
          <Card title={"Pre Bridal"} description={"Get ready to shine on your big day with our exclusive prebridal packages, pampering you from head to toe for a radiant and picture-perfect bridal glow."}/>
        </div>
        <div className="flex flex-col items-center justify-end space-y-3 w-full px-2 md:px-0 md: md:w-[60%]">
          <h3 className="font-be-jost-pro text-3xl font-semibold text-white">
            Services <span className="text-[#FBB034]">Menu</span>
          </h3>
          <p className="text-[#9A9A9A] font-be-jost-pro text-xl font-normal text-center">
          We have been consistently trying to increase the number of grooming services we offer to our pan-India clientele. For the time being, we are offering the ones mentioned below.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center items-center w-full space-x-0 md:space-x-2 space-y-5 md:space-y-0">
          <div className="flex flex-col w-full md:w-4/12 items-center justify-center space-y-5">
            <Menu title={"Hair Styling"} price={200} description={"Let us make you look your best with the hair styling expertise we have mastered over the years!"}/>
            <Menu title={"Hair Washing"} price={400} description={"Tired of washing your hair on your own? Sit back and relax while our experts take care of your hair-washing needs!"}/>
            <Menu title={"Body Massage"} price={500} description={"We have a huge team of skilled and certified masseuses under our payroll. Contact us now and relax your muscles ASAP! "}/>
            <Menu title={"Thai Massage"} price={300} description={"We have recently hired expert masseuses specializing in Thai body massage. Give us a call now!"}/>
          </div>
          <div className="flex flex-col w-full md:w-4/12 items-center justify-center space-y-5 md:space-y-6">
            <Menu title={"Beauty Spa"} price={400} description={"Let your skin breathe with our beauty spa services specially designed for the Indian climate and Indian skin variants"}/>
            <Menu title={"Stylish Shaving"} price={400} description={"Enjoy a warm (and close) shave with our range of stylish shaving packages."}/>
            <Menu title={"Hair Colouring"} price={500} description={"We help men and women get their desired coloured hair in no time and that too at reasonable rates!"}/>
            <Menu title={"Moustache Trim"} price={300} description={"A well-trimmed moustache and a properly maintained beard are synonymous with a modern alpha male!"}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
