import React from "react";

const AboutHelper = ({ isAbout = false }) => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-around w-full min-h-[60] py-16 px-3 md:px-0 bg-[#202125] ">
      <img
        src="/Assets/homepage-2.png"
        className="w-full md:w-1/2 lg:w-1/3 mt-16 md:mt-0"
      />
      <div className="flex flex-col items-start justify-center w-full md:w-1/3 space-y-6">
        <p className="text-[#FBB034] text-lg font-normal font-be-jost-pro w-full text-center md:text-left">
          About Us
        </p>
        <p className="font-be-jost-profont-normal text-base text-[#9A9A9A]">
        Welcome to Barbera, a visionary start-up founded by Sumit Kumawat, an accomplished B.tech graduate from IIT Patna. Since August 2020, we have been revolutionizing the personal grooming sector in India.
        <br></br>At Barbera, we pride ourselves as a leading provider of exceptional salon services for both men and women, conveniently offered in the comfort of your home at affordable rates.
        <br></br>Our unisex salon services are designed to cater to busy individuals, including working parents, professionals, the elderly, frequent travellers, entrepreneurs, and the youth.
        <br></br>With our user-friendly app available on iOS and Android, booking your preferred services has never been easier.
        <br></br>Currently serving in Jaipur, Rajasthan, we have ambitious plans to expand our reach to all tier 1, tier 2, and tier 3 cities across India.
        <br></br>Having delighted over 25,000 patrons in Jaipur, we are committed to empowering salon workers and supporting talented individuals in the industry.
        <br></br>Continuously evolving, we enhance our app features and grow our skilled workforce to provide expedited world-class services.
        <br></br>Join us at Barbera as we redefine your grooming experience with utmost convenience and excellence.
        </p>
        <a href="/contact" className="bg-[#FBB034] w-full lg:w-2/5 py-3 rounded-md font-bold text-center">
        <button >
          {isAbout ? "Book An Appointment" : "Learn More"}
        </button>
        </a>
      </div>
    </div>
  );
};

export default AboutHelper;
