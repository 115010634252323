import React, { useState,useEffect ,useRef } from "react";
import axios from "axios";
import { IoMdCloudUpload } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
import "../../index.css";
import { useQuery, gql, useMutation } from "@apollo/client";

const GET_INVENTORY_LIST= gql`
query{
  getInventory{
    status
    message
    inventoryList{
      id
      name
      canDecreased
  }
  }
}`

// const ADD_EMPLOYEE = gql`
// mutation addEmployee($fullName:String!,$contactNumber:String!,$inventoryList:[InventoryType!]!){
//   addEmployee(data:{
//     fullName: $fullName
//     contactNumber: $contactNumber
//     inventoryList:$inventoryList
//   }){
//     status
//     message
//   }
// }`;

const ADD_EMPLOYEE = gql`
mutation addEmployee($data:employeeInput!){
  addEmployee(data:$data){
    status
    message
  }
}`;

const AddEmployeeForm = ({ setAddEmployee }) => {
  const [fullName, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [inventoryList, setInventoryList] = useState([
    { inventoryId: "", price: "" },
  ]);
  const [dataList, setDataList] = useState([]);
  const [govtIdImage, setGovtIdImage] = useState("");
  const [vehicleIdImage, setVehicleIdImage] = useState("");
  const [LicenseImage, setLicenseImage] = useState("");
  const [isFetched, setIsFetched] = useState(false);
  const govtId = useRef(null);
  const vehicleId = useRef(null);
  const license = useRef(null);
  const [baseLat, setBaseLat] = useState("");
  const [baseLong, setBaseLong] = useState("");
  const [assignedGender, setAssignedGender] = useState("");

  const { loading, error, data } = useQuery(GET_INVENTORY_LIST);
  const [addEmployee, { addLoading, addError }] = useMutation(ADD_EMPLOYEE);

  // const [mutationHandler, { data, loading }] = useMutation(YOUR_MUTATION, {
  //   onError: (err) => {
  //       setError(err);
  //   }
  // });

  
  useEffect(()=>{
    try{
      console.log('error is');
    console.log(error);
    console.log('inventory list is');
    console.log(data.getInventory.inventoryList);
    
    setDataList(data.getInventory.inventoryList);
    setIsFetched(true);
    }catch(err){
      console.log(err);
    }
    
  },[data])

  const handleGovtIdUpload = async(e) => {
    const file = e.target.files[0];
    // console.log(file.file);
    // setGovtIdImage(file);
   e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === 'Success'){
          setGovtIdImage(data.url);
        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  const handleBrowseGovtIdClick = () => {
    govtId.current.click();
  };

  const handleVehicleIdUpload =async (e) => {
    const file = e.target.files[0];
    // setVehicleIdImage(file);
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === 'Success'){
          setVehicleIdImage(data.url);
        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  const handleBrowseVehicleIdClick = () => {
    vehicleId.current.click();
  };

  const handleLicenseUpload = async(e) => {
    const file = e.target.files[0];
    // setLicenseImage(file);
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === 'Success'){
          setLicenseImage(data.url);
        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };

  const handleBrowseLicenseClick = () => {
    license.current.click();
  };

  const handleAdd = (e) => {
    e.preventDefault();
    let finalDataObj={};
    finalDataObj.fullName= fullName;
    finalDataObj.contactNumber= contactNumber;
    finalDataObj.inventoryList= inventoryList;
    finalDataObj.governmentId= govtIdImage;
    finalDataObj.vehicleId= vehicleIdImage;
    finalDataObj.license= LicenseImage;
    finalDataObj.gender= assignedGender;
    finalDataObj.baseLat= baseLat;
    finalDataObj.baseLong= baseLong;
    console.log(finalDataObj);
   
    // addEmployee({ 
    //   variables:{  
    //     data: fullName,
    //     contactNumber: contactNumber,
    //     inventoryList:[
    //       {
    //         inventoryId: "263491cd-bc98-4348-9b8e-deec5af1dd64",
    //         price:60
    //       },
    //       {
    //         inventoryId: "e8d66dfc-9f68-43e3-ae9b-5eb840b9c012",
    //         price: 40
    //       }
    //     ]
    //   }
    // }).then((res)=>{
    //   console.log('res is');
    //   // console.log(res);
    //   // if(res.data.addBanner.status === "SUCCESS"){
    //   //   window.location.reload();
    //   // }
    // }).catch(err=>{
    //   console.log(err);
    // })

    //================================
    
    addEmployee({ 
      variables:{  
        data: finalDataObj
      }
    }).then((res)=>{
      console.log('res is');
      console.log(res);
      if(res.data.addEmployee.status === "SUCCESS"){
        window.location.reload();
      }
    }).catch(err=>{
      console.log(err);
    })
    // setAddEmployee(false);
  };

  const handleInventoryChange = (index, event) => {
    const { name, value } = event.target;
    const updatedList = [...inventoryList];
    updatedList[index][name] = value;
    setInventoryList(updatedList);
  };

  const handleInventoryPriceChange = (index, event) => {
    const { name, value } = event.target;
    const updatedList = [...inventoryList];
    updatedList[index][name] =parseInt(value);
    setInventoryList(updatedList);
  };

  const handleRemoveRow = (index) => {
    const updatedList = [...inventoryList];
    updatedList.splice(index, 1);
    setInventoryList(updatedList);
  };

  const handleAddRow = () => {
    setInventoryList([...inventoryList, { inventoryId: "", price: "" }]);
  };

  return (
   isFetched &&
   (
    <div className="flex flex-col space-y-10">
    <h3 className="font-be-jost-pro font-bold text-2xl text-[#FBB033]">
      Add Employee
    </h3>

    <input
      type="text"
      placeholder="Name*"
      className="px-2 py-1 bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none text-white w-5/6"
      value={fullName}
      onChange={(e) => setName(e.target.value)}
    />

    <div className="flex items-end">
      <span className="py-1 border-b-2 border-b-[#E0E0E0] text-[#8aa3af]">
        +91
      </span>
      <input
        type="text"
        placeholder="Mobile Number*"
        className="px-2 py-1 bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none text-white w-5/6"
        value={contactNumber}
        onChange={(e) => setContactNumber(e.target.value)}
      />
    </div>
    {inventoryList.map((inventory, index) => {
      return (
        <div className="flex space-x-2 lg:space-x-8" key={index}>
          <select
            className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none ${
              inventory.inventoryId === "" ? " text-[#9A9A9A]" : "text-white"
            } w-1/2 lg:w-[25%]`}
            value={inventory.inventoryId}
            onChange={(e) => handleInventoryChange(index, e)}
            name="inventoryId"
          >
            <option value="" disabled hidden>
              Inventory*
            </option>
            {/* <option value="Hair Cutting Kit">Hair Cutting Kit</option>
            <option value="Hair Color Kit">Hair Color Kit</option> */}
          {
            dataList.map((item,i)=>(
              <option value={item.id} key={i}>{item.name}</option>
            ))
          }
          </select>
          <div className="flex items-center">
            <span
              className="text-[#FBB033] font-be-jost-pro text-base font-normal cursor-pointer"
              onClick={handleAddRow}
            >
              {`Add Inventory +  `}
            </span>
            {inventoryList.length !== 1 && (
              <AiOutlineDelete
                className="text-[#FBB033] text-2xl cursor-pointer"
                onClick={() => handleRemoveRow(index)}
              />
            )}
          </div>
          <input
            type="number"
            placeholder="Amount*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
            value={inventory.price}
            onChange={(event) => handleInventoryPriceChange(index, event)}
            name="price"
          />
        </div>
      );
    })}

 <select
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-4/6`}
          value={assignedGender}
          onChange={(e) => setAssignedGender(e.target.value)}
        >
          <option value="" disabled hidden>
            Gender*
          </option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
        <div className="flex space-x-2 lg:space-x-8" >
        <input
            type="text"
            placeholder="Lat*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
            value={baseLat}
            onChange={(e) => setBaseLat(e.target.value)}
            name="Lat"
          />
         
         <input
            type="text"
            placeholder="Long*"
            className="px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-1/3"
            value={baseLong}
            onChange={(e) => setBaseLong(e.target.value)}
            name="Lat"
          />
        </div>
    <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 w-full lg:items-center lg:w-4/5">
      <div className="flex items-center w-full lg:w-1/2">
        <input
          id="govt-id"
          type="file"
          ref={govtId}
          className="hidden"
          onChange={handleGovtIdUpload}
        />
        <input
          type="text"
          placeholder="Government Id*"
          disabled={true}
          className="w-1/2 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
        />
        <p className="ml-3 mr-2 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer">
          Upload ID
        </p>
        <IoMdCloudUpload
          className="text-[#FBB033] text-3xl"
          onClick={handleBrowseGovtIdClick}
        />
      </div>
      <div className="flex items-center w-full lg:w-1/2">
        <input
          id="vehicle-id"
          type="file"
          ref={vehicleId}
          className="hidden"
          onChange={handleVehicleIdUpload}
        />
        <input
          type="text"
          placeholder="Vehicle Id*"
          disabled={true}
          className="w-1/2 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
        />
        <p className="ml-3 mr-2 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer">
          Upload ID
        </p>
        <IoMdCloudUpload
          className="text-[#FBB033] text-3xl"
          onClick={handleBrowseVehicleIdClick}
        />
      </div>
    </div>

    <div className="flex items-center">
      <input
        id="license"
        type="file"
        ref={license}
        className="hidden"
        onChange={handleLicenseUpload}
      />
      <input
        type="text"
        placeholder="License*"
        disabled={true}
        className="w-3/5 lg:w-1/5 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white"
      />
      <p className="ml-3 mr-2 font-be-jost-pro text-[#FBB033] font-normal text-base cursor-pointer">
        Upload ID
      </p>
      <IoMdCloudUpload
        className="text-[#FBB033] text-3xl"
        onClick={handleBrowseLicenseClick}
      />
    </div>

    <div className="flex py-16 w-full justify-center items-center">
      <button
        className="px-5 py-2 bg-[#FBB034] font-poppins text-sm font-bold rounded-md"
        onClick={handleAdd}
      >
        Add Employee
      </button>
    </div>
  </div>
   )
  );
};

export default AddEmployeeForm;
