import React from "react";
import Footer from "./Components/Footer";

const Terms = () => {
  return (
    <>
      <div className="w-full  h-auto ">
        <div className="flex flex-col items-center md:justify-around md:items-center w-full h-auto p-10 bg-[#11120F] space-y-4">
          <p className="text-[#FBB034] text-lg font-normal font-be-jost-pro w-full text-center md:text-left">
            Terms and Conditions
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          These Terms govern your access to, and usage of all content, product and services available at Barbera’s official website. Your access to our services is subject to your acceptance, without modification, of all of the terms and conditions contained herein and all other operating rules and policies published and that may be published from time to time by us. Please read the agreement carefully before accessing or using our services. By accessing or using any part of our services, you agree to be bound by these terms. If you do not agree to any part of the terms of the agreement, then you may not access or use our services and henceforth, leave this website at your earliest convenience.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Intellectual Property
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          The agreement does not transfer from us to you any of our or third party intellectual property, and all rights, title, and interest in and to such property will remain solely with Barbera.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Third-Party Services
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          In using the services, you may use third-party services, products, software, embeds, or applications developed by a third party. If you use any third-party services, you understand that any use of a third-party service is at your own risk. And Barbera shall not be responsible or liable to anyone for third-party websites or services. You acknowledge and agree that Barbera shall not be responsible or liable for any damage or loss caused or alleged to be caused by or in connection with the use of any such content, goods or services available on or through any such websites or services.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Accounts
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Where the use of any part of our services requires an account, you agree to provide us with complete and accurate information when you register for an account. You will be solely responsible and liable for any activity that occurs under your account. You are responsible for keeping your account information up-to-date and for keeping your password secure. You are responsible for maintaining the security of your account that you use to access the service we at Barbera offer. You shall not share or misuse your access credentials. You must notify us immediately of any unauthorized uses of your account or upon becoming aware of any other breach of security.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Termination
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          We may terminate or suspend your access to all or any part of our services at any time, with or without cause, with or without notice, effective immediately. If you wish to terminate the agreement or your Barbera account, you may simply discontinue using our services. All provisions of the agreement which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Disclaimer
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Our services are provided on an ‘as is’ and ‘as available’ basis. Barbera, its suppliers and licensors hereby disclaim all warranties of any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a particular purpose and non-infringement. Neither Barbera nor its suppliers and licensors, make any warranty that our services will be error-free or that access thereto will be continuous or uninterrupted. You understand that you download from, or otherwise obtain content or services through, our services at your own discretion and risk.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Jurisdiction and Applicable Law
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Except to the extent any applicable law provides otherwise, the agreement and any access to or use of our services will be governed by the laws of India. The proper venue for any disputes arising out of or relating to the agreement and any access to or use of our services will be the state and federal courts located in India.
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Changes
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Barbera reserves the right, at our sole discretion, to modify or replace these terms at any time. If we make material changes, we will let you know by posting on our website, or by sending you an email or other communication before the changes take effect. The notice will designate a reasonable time after which the new terms will take effect. If you disagree with our changes, then you should stop using our services within the designated notice period, or once the changes become effective. Your continued use of our services will be subject to the new terms.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Refund Policy
          </p>
          
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Cancellation and Rescheduling:
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Should you need to cancel or reschedule your appointment, kindly inform us at least 24 hours before the scheduled service time to be eligible for a full refund.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Dissatisfaction with Service:
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          If you are dissatisfied with the service provided, please notify us within 24 hours of the appointment. Our customer support team will promptly address your concerns and, if necessary, arrange for a complimentary re-service or a refund of the service cost.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Refund Processing: 
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Refunds will be processed within 3 business days from the date of your request. Please note that the refund amount may vary based on the service provided and the nature of the concern.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Refund Eligibility:
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Refunds are applicable only to the service cost and do not cover any add-ons or additional products purchased during the appointment.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Refund Contact: 
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          To request a refund or express any concerns, please contact our customer support team at support@barbera.in.  We appreciate your trust in our services and aim to ensure a seamless and satisfactory experience with every visit.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Note: 
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          This refund policy is subject to change and may be updated at the discretion of Barbera. Customers are advised to review the policy periodically for any modifications.
          </p>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default Terms;
