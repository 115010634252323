import React from "react";
import Footer from "./Components/Footer";

const Privacy = () => {
  return (
    <>
      <div className="w-full  h-auto ">
        <div className="flex flex-col items-center md:justify-around md:items-center w-full h-auto p-10 bg-[#11120F] space-y-4">
          <p className="text-[#FBB034] text-lg font-normal font-be-jost-pro w-full text-center md:text-left">
            Privacy Policy
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Welcome to the official website of Barbera. We understand that your online privacy is important to you. This statement governs our privacy policies concerning those users of this website who visit without the intention of transacting and visitors who register on our website to transact on this website and use the services we at Barbera offer.
Your access to our services is subject to your acceptance, without modification, of all of the terms and conditions contained herein and all other operating rules and policies published and that may be published from time to time by us.
Please read the Agreement carefully before accessing or using our services. By accessing or using any part of our services, you agree to be bound by these terms. If you do not agree to any part of the terms of the agreement, then you may not access or use our services and please leave this website at your earliest convenience.

          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          We collect personally identifiable information from you
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          Here, Personally Identifiable Information refers to any information that identifies or can be used to identify, contact, or locate an individual. Here PII consists of but is not limited to the name, address, phone number, email address, financial profiles, and credit card information. 
We do not collect Personally Identifiable Information anonymously. We also do not collect demographic information that is not connected to our registered customers or required by us to conduct business transactions with them.
We may collect basic user profile information from all of our website visitors. We collect the following additional information from our registered customers: the names, addresses, phone numbers, and email addresses of registered customers.

          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          The personally identifiable information we collect is being used by other concerning organizations
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          In addition to our direct collection of information, our third-party service vendors like credit card companies, and banks may collect this information from our website visitors and registered customers.
Please note that we do not control how these third parties use the information they collect but rest assured that the top brass at Barbera does periodically ask third-party financial service providers how they are using the PII they are collecting or have collected on our website visitors and registered customers. 

          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          How do we use Personally Identifiable Information?
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          We use Personally Identifiable Information to improve our website’s User Interface as well as user experience. We also periodically improve the services we already offer and at the same time add or remove services as per the requirements of our current and potential customers.
We periodically may contact our registered customers via emails or phone calls regarding research or purchase and selling opportunities on the website of Barbera. 
We may also use Personally Identifiable Information to contact our registered customers in response to specific inquiries generated by them.

          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          With whom do we share the collected information?
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          The Personally Identifiable Information that we collect about our registered customers is shared with other registered customers who wish to compare potential transactions with other registered customers. 
We may share aggregated information about our website visitors, including the demographics of our website visitors and registered customers with the agencies affiliated with Barbera as well as the third-party vendors with whom we do business transactions.
We also offer our customers the option to “opt out” of receiving information from us or being contacted by any agency acting on our behalf.

          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          How do we store the Personally Identifiable Information we collect?
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
          The Personally Identifiable Information collected by Barbera is securely stored. The stored information is access controlled hence it is accessible to third parties or employees of Barbera except for use as indicated in the above sections.
          </p>
          
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default Privacy;
