import React, { useState,useEffect } from "react";
import DataTable from "../DataTable";
import Switch from "react-switch";
import AddEmployeeForm from "./AddEmployeeForm";
import EditEmployeeForm from "./EditEmployeeForm";
import { gql,useMutation,useQuery } from "@apollo/client";

const DELETE_EMPLOYEE= gql`
mutation deleteEmployee($data:ID!){
  deleteEmployee(data:$data){
      status
      message
    }
  }
  `;

  const EMPLOYEE_ACTIVE = gql `
  mutation updateEmployeeActive($data:empActive!){
    updateEmployeeActive(data:$data){
        status
        message
      }
    }`

    const GET_EMPLOYEES= gql`
query{
  getEmployeelist{
    status
    message
    employee{
      id
      fullName
      totalPrice
      contactNumber
      isOnBoarded
      governmentId
      vehicleId
      license
      employeechild{
        id
        price
        employeeInventory{
          id
          name
        }
      }
    }
  }
}
`

const GET_EMPLOYEE_LOCATION= gql`
mutation getEmployeeLocation($data:ID!){
  getEmployeeLocation(data:$data){
    status
    message
    employee{
      contactNumber
      fullName
      lat
      long
    }
    }
  }
  `;
const EmployeeManagementTable = ({
  data,
  addEmployee,
  setAddEmployee,
  editForm,
  setEditForm,
  editData,
  setEditData,
  isFetched,
}) => {
  // const [editForm, setEditForm] = useState(false);
  

  const [employeeList,setEmployeeList] = useState(data);
const [isToggleChecked,setIsToggleChecked] = useState(false);


  const [deleteEmployee, { deleteLoading, deletError }] = useMutation(DELETE_EMPLOYEE);
const [changeEmpStatus,{error:statusError,loading:statusLoading}]= useMutation(EMPLOYEE_ACTIVE)
const [getEmployeeLocation,{error:locationError,loading:locationLoading}]= useMutation(GET_EMPLOYEE_LOCATION)

  const handleEditClick = (data) => {
    setEditData(data);

    setEditForm(true);
  };

  const handleRemoveRow =(dataId)=> {
    deleteEmployee({ 
      variables:{ data: dataId}
    }).then((res)=>{
      // window
      console.log(' delete res is');
      console.log(res);
      if(res.data.deleteEmployee.status === "SUCCESS"){
        window.location.reload();
      }
    })
  };

  console.log('data table page for employees');
  console.log(data);

  const showInMapClicked = (empId) => {
    getEmployeeLocation({ 
      variables:{ data: empId}
    }).then((res)=>{
      // window
      console.log('location res is');
      console.log(res);
      if(res.data.getEmployeeLocation.status === "SUCCESS"){
        if(res.data.getEmployeeLocation.employee.lat && res.data.getEmployeeLocation.employee.long){
          window.open(`https://maps.google.com?q=${res.data.getEmployeeLocation.employee.lat},${res.data.getEmployeeLocation.employee.long}`);
          // window.open(`https://maps.google.com?q=17.415157,78.450071`);
        }else{
          alert('location not updated');
        }
        // window.location.reload();
      }
    })
    // window.open("https://maps.google.com?q="+37.4219983+","+-122.084 );
    // window.open("https://maps.google.com?q=+17.3939131,+78.4780647");
  };

  return (
    <>
      {addEmployee ? (
        <AddEmployeeForm setAddEmployee={setAddEmployee} />
      ) : editForm ? (
        <EditEmployeeForm setEditForm={setEditForm} editData={editData} />
      ) : (
        <div>
          {
            isFetched &&
            (
              <DataTable tableName="Employee-managment">
            <thead>
              <tr>
                <th data-priority="1" className="text-white">
                  Name
                </th>
                {/* <th data-priority="2" className="text-white">
                  Inventory
                </th> */}
                <th data-priority="3" className="text-white">
                  Amount
                </th>
                <th data-priority="4" className="text-white">
                  Mobile Number
                </th>
                <th data-priority="5" className="text-white">
                  Active
                </th>
                <th data-priority="5" className="text-white">
                  Distance
                </th>
                <th data-priority="6" className="text-white">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* <tr> */}
              {employeeList.map((row,i) => {
                return (
                  <tr className="border-b-2 border-b-[#E0E0E0]">
                    <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                      {row.fullName}
                    </td>
                    {/* <td className="w-[14%] text-center border-b-[1px] border-b-[#E0E0E0]">
                     
                      {
                        row.employeechild.length>0 ?
                        (
                          row.employeechild.map((obj) => (
                            <p className="text-white mb-2">{ obj.employeeInventory && obj.employeeInventory.name}</p>
                            
                          ))
                        )
                        :(
                          <p className="text-white mb-2"></p>
                        )
                        
                      }
                     
                    </td> */}
                    <td className="w-[8%] text-center border-b-[1px] border-b-[#E0E0E0]">
                      {/* {row.inventory.map((obj) => (
                        <p className="text-white">{`x${obj.amount}`}</p>
                      ))} */}
                      <p className="text-white">{row.totalPrice}</p>
                    </td>
                    <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{`${row.contactNumber}`}</td>
                    <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">
                      {/* {row.isOnBoarded ? <img src="/Assets/active.png" /> : null} */}
                      {/* <Switch onChange={handleToggleChange} checked={isToggleChecked} /> */}
                      <Switch
                        checked={row.isOnBoarded}
                        // onChange={handleToggleChange}
                        onChange={(e) => changeEmpStatus({ 
                          variables:{data:{
                            id:row.id,
                            isOnBoarded: row.isOnBoarded
                          }}
                        }).then((res)=>{
                          // window
                          console.log(' delete res is');
                          console.log(res);
                          if(res.data.updateEmployeeActive.status === "SUCCESS"){
                            window.location.reload();
                          
                          }
                        })
                      }
                        // onColor="#86d3ff"
                        // onHandleColor="#2693e6"
                        // handleDiameter={30}
                        // uncheckedIcon={false}
                        // checkedIcon={false}
                        // offColor="#ffffff"
                        // onColor="#ffffff"
                        // offHandleColor="#fbb033"
                        // onHandleColor="#fbb033"
                        // boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        // activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        // height={20}
                        // width={48}
                        // className="react-switch"
                        onColor="#fbb033"
            onHandleColor="#ffffff"
            handleDiameter={30}
            // offColor="rgb(136, 136, 136)"
            offHandleColor="#757575"
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
                        id={row.id}
                     />
                    </td>
                    <td className="w-[8%] text-center border-b-[1px] border-b-[#E0E0E0]">
                     
                      <p className="text-white">{row.totalDistance ? row.totalDistance : 0}</p>
                    </td>
                    <td className="w-[10%] text-center px-2 border-b-[1px] border-b-[#E0E0E0]">
                      <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center h-full lg:space-x-2">
                        <button className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                         onClick={()=>showInMapClicked(row.id)}
                        >
                          Track
                        </button>
                        <button
                          className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                          onClick={() => handleEditClick(row)}
                        >
                          Edit
                        </button>
                        <button className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium" onClick={() => handleRemoveRow(row.id)}>
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </DataTable>
            )
          }
        </div>
      )}
    </>
  );
};

export default EmployeeManagementTable;
