import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
 
const config= {
    apiKey: "AIzaSyC2xERVq6YhyJ6YnhmS9luip3Nw2XNvOHM",
    authDomain: "barbera-71195.firebaseapp.com",
    projectId: "barbera-71195",
    storageBucket: "barbera-71195.appspot.com",
    messagingSenderId: "986340179444",
    appId: "1:986340179444:web:e15697dfd389a4ce8d8da1"
  };
  
  firebase.initializeApp(config);
let auth = firebase.auth();
export { auth, firebase };