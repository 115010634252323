import React, { useState } from "react";
import axios from "axios";
import {gql,useMutation} from '@apollo/client'


const ADD_BANNER = gql`
mutation addBanner($image:String!){
  addBanner(data:{
    image: $image
  }){
    status
    message
  }
}
`;
// const ADD_BANNER = gql`
// mutation addBanner($image:String!){
//   addBanner(data:{
//     image: $url
//   }){
//     status
//     message
//   }
// }
// `

// const ADD_PROMOCODE = gql`
// mutation CreatePromocode($type:String!, $promocode:String!,$discount:Int!, $name:String!){
//   addPromocode(data:{
//     type: $type
//     promocode: $promocode
//     discount:$discount
//     name:$name
//   }){
//     status
//     message
//   }
// }
// `
const UploadBannerModal = ({ setShowModal }) => {
  const [image, setImage] = useState("");
  const [files,setFiles] = useState(null);


  const [addBanner, { loading, error }] = useMutation(ADD_BANNER);
  const onFileUpladChange=(e)=>{
    setFiles({ file: e.target.files[0]})
  }


  const formDataSubmitHandler = async (e) => {
    e.preventDefault();
    const url = `https://api.barbera.in/img/upload`;
    try {
     
      const formData = new FormData();
     
      formData.append('file', files.file);
  
      const { data } = await axios.post(url, formData);
        console.log(data);
        if(data.status === "Success"){
          // window.location.reload();
          // setImage()
          addBanner({ 
            variables:{ image: data.url}
          }).then((res)=>{
            // window
            console.log('res is');
            console.log(res);
            if(res.data.addBanner.status === "SUCCESS"){
              window.location.reload();
            }
          })

        }
      
    } catch (error) {
      console.error(error);
      window.alert(error.message + " Try Again");
      // history.push("/dashboard/compliance");
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-1/3 my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-black outline-none focus:outline-none p-8">
            {/*header*/}
            <div className="flex flex-col items-center justify-center">
              <div
                className="w-full flex items-center justify-end cursor-pointer text-white"
                onClick={() => setShowModal(false)}
              >
                <p className="w-6 h-6">×</p>
              </div>
              <div className="w-full flex items-center justify-center p-5 rounded-t">
                <h3 className="text-xl font-normal font-be-jost-pro text-[#FBB034]">
                  Upload Banner
                </h3>
              </div>
            </div>
            <div className="flex flex-col w-full items-center justify-center space-y-8">
              <div className="flex flex-col w-full items-center space-y-5">
                {/* <input
                  type="text"
                  placeholder="Name*"
                  className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white w-5/6"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                /> */}
                <input type="file" className="px-2 py-1 bg-black border-b-2 border-b-[#E0E0E0] outline-none text-white w-5/6" onChange={onFileUpladChange}  name="file"
                accept="image/png, image/jpeg"
                />
              </div>

              <div className="flex justify-center items-center space-x-5 w-1/2">
                <button
                  className="w-3/5 bg-[#FBB034] p-2 rounded-md text-[#191919] font-be-jost-pro font-semibold text-base"
                  // onClick={() => setShowModal(false)}
                  onClick={formDataSubmitHandler}
                >
                  Add Banner
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default UploadBannerModal;
