import React, { useState, useEffect } from "react";
import Chart from 'react-apexcharts'
import { Link, useLocation } from "react-router-dom";
import { AiOutlineDown } from "react-icons/ai";
import "./index.css";
import Sidebar from "./Components/Sidebar";
import Layout from "./Components/Layout";
import LogoutModal from "./Components/LogoutModal";
import { useQuery, gql,useMutation } from "@apollo/client";

const GET_TOTALBOOKINGS= gql`
  query{
    getTotalbookings{
      message
      status
      Totalbookings
  }
} 
`

const GET_TOTALEARNINGS= gql`
query{
  getEarnings{
    message
    status
    Earnings
  }
}
`
const GET_DASHBOARD= gql`
  mutation getDashboardDetails($data:String!){
    getDashboardDetails(data:$data){
        status
        message
        dashboardDetails{
          bookingCount
          totalAmount
        }
        months
        Bookings
        Amount
      }
    }
  `;




const AdminDashboard = () => {
  const [total,settotal]= useState(0);
  const [isFetched, setIsFetched] = useState(false);
  const [getDashboardDetails, {data:dashboardData, loading: prodDeletLoading, error:producterror }] = useMutation(GET_DASHBOARD);
  const [bookingData,setBookingData] = useState({});
  const [bookingChartData,setBookingChartData] = useState({});
  const [filterValue,setFilterValue] = useState('');
  // const [data]
  useEffect(() => {
    
    getDashboardDetails({ 
      variables:{ data: "overall"}
    }).then((res)=>{
      // window
      console.log(' getDashboardDetails res is');
      console.log(res);
      if(res.data.getDashboardDetails.status === "SUCCESS"){
        setBookingData(res.data.getDashboardDetails.dashboardDetails);

        const bookingOptions= {
          chart: {
            id: 'apexchart-example'
          },
          colors : ["#fbb337"],
          xaxis: {
            categories: res.data.getDashboardDetails.months
          }
        }
        
        const bookingSeries= [{
          name: 'Bookings',
          data: res.data.getDashboardDetails.Bookings
        }]
        
        const amtSeries= [{
          name: 'Amount',
          data: res.data.getDashboardDetails.Amount

        }]
        let bookingObj={};
        bookingObj.options=bookingOptions;
        bookingObj.series=bookingSeries;
        bookingObj.amtseries=amtSeries;
        setBookingChartData(bookingObj);
      setIsFetched(true);
      }
     
    })
  }, []);
  // const {error,loading, data} = useQuery(GET_TOTALBOOKINGS);
  // console.log(error, loading, data);
 
  // if(error){
  //   console.log(error);
  // }
  // const earnings = useQuery(GET_TOTALEARNINGS);
  // console.log(earnings.error,earnings.loading,earnings.data);
  // if(earnings.error){
  //   console.log(earnings.error);
  // }
  const onFilterChange=(e)=>{
    setIsFetched(false);
    setFilterValue(e.target.value);
    getDashboardDetails({ 
      variables:{ data: e.target.value}
    }).then((res)=>{
      // window
      console.log('new getDashboardDetails res is');
      console.log(res);
      if(res.data.getDashboardDetails.status === "SUCCESS"){
        setBookingData(res.data.getDashboardDetails.dashboardDetails);
        const bookingOptions= {
          chart: {
            id: 'apexchart-example'
          },
          colors : ["#fbb337"],
          xaxis: {
            categories: res.data.getDashboardDetails.months
          }
        }
        
        const bookingSeries= [{
          name: 'Bookings',
          data: res.data.getDashboardDetails.Bookings
        }]
        
        const amtSeries= [{
          name: 'Amount',
          data: res.data.getDashboardDetails.Amount

        }]
        let bookingObj={};
        bookingObj.options=bookingOptions;
        bookingObj.series=bookingSeries;
        bookingObj.amtseries=amtSeries;
        setBookingChartData(bookingObj);
      setIsFetched(true);
      }
     
    })
  }
  return (
    <>
      {
        isFetched ?
        (
          <div className=" h-screen flex">
        <Sidebar />
        <Layout>
          <div className="flex flex-col space-y-6">
            <div className="flex justify-center xl:justify-start   text-[#FBB033] items-center font-be-jost-pro text-xl font-bold space-x-3 pt-8 pl-5">
              {/* <p>April Activity</p> */}
              <select
          placeholder="Category*"
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none`}
          value={filterValue}
          onChange={onFilterChange}>
             <option value="overall" selected>overall</option>
              <option value="7">7 days</option>
              <option value="30">30 days</option>
              <option value="90">3 months</option>
              <option value="182">6 months</option>
              <option value="365">12 months</option>
             
        </select>
              {/* <AiOutlineDown className="cursor-pointer" /> */}
            </div>
            <div className="flex flex-col xl:flex-row space-y-6 xl:space-y-0 justify-around items-center xl:space-x-6">
              <div className="w-[90%] xl:w-1/5 bg-[#FBB033] rounded-3xl flex flex-col justify-around items-center space-y-10 px-5 py-7">
                <img src="/Assets/dashboard-1.png" />
                <div className="w-[90%] bg-[#ECF1FF] h-2"></div>
                <p className="text-[#39354E] font-semibold text-sm font-be-jost-pro">
                  Total Earnings
                </p>
                <div className="bg-white min-w-1/2 px-2 py-7 rounded-md h-16 flex justify-center items-center ">
                  <p className="text-[#39354E] font-bold text-base font-be-jost-pro">
                  {/* {(earnings.loading==false)? earnings.data.getEarnings.Earnings: total } */}
                   {bookingData.totalAmount == null ? 0 : bookingData.totalAmount}
                  </p>
                </div>
              </div>
              <div className="w-[90%] xl:w-1/5 bg-[#FBB033] rounded-3xl flex flex-col justify-around items-center space-y-10 px-5 py-7">
                <img src="/Assets/Security.png" />
                <div className="w-[90%] bg-[#ECF1FF] h-2"></div>
                <p className="text-[#39354E] font-semibold text-sm font-be-jost-pro">
                  Total Bookings
                </p>
                <div className="bg-white min-w-1/2 px-2 py-7 rounded-md h-16 flex justify-center items-center">
                  <p className="text-[#39354E] font-bold text-xl font-be-jost-pro">
                    {/* {data.getTotalbookings.Totalbookings} */}
                    {/* {(loading==false)? data.getTotalbookings.Totalbookings: total } */}
                    {bookingData.bookingCount}
                  </p>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <p className="text-center xl:text-left text-[#FBB033] font-be-jost-pro text-xl font-bold">
                  Bookings
                </p>
                {/* <img src="/Assets/LineGraph.png" /> */}
                <Chart options={bookingChartData.options} series={bookingChartData.series} type="bar" width={500} height={320} />
              </div>
              
            </div>
            <div className="flex flex-col space-y-4" style={{marginLeft:'65%'}}>
                <p className="text-center xl:text-left text-[#FBB033] font-be-jost-pro text-xl font-bold">
                  Amount
                </p>
                {/* <img src="/Assets/LineGraph.png" /> */}
                <Chart options={bookingChartData.options} series={bookingChartData.amtseries} type="bar" width={500} height={320} />
              </div>
          </div>
        </Layout>
      </div>
        )
        :(
          <div className=" h-screen flex" style={{background:'#000000'}}>
        <Sidebar />
        
        </div>
        )
      }
    </>
  );
};

export default AdminDashboard;
