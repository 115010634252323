import {React,useState,useEffect} from "react";
// import moment from 'moment';
import { Link } from "react-router-dom";
import DataTable from "../DataTable";
import { useQuery, gql,useMutation } from "@apollo/client";


const UPCOMING_BOOKING_LIST=gql`
query{
  getUpcomingbooking{
    status
    message
    booking{
      id
      price
      totalPrice
      status
      address
      bookingDate
      timeSlot
      paymentType
      bookingId
      orderDetail{
        id
        name
        price
      }
      employee{
        id
        fullName
      }
      user{
        id
        fullName
      }
    }
  }
}
`;

const PREVIOUS_BOOKING_LIST=gql`
query{
  getPreviousbooking{
    status
    message
    booking{
      id
      price
      totalPrice
      address
      status
      bookingDate
      timeSlot
      paymentType
      bookingId
      orderDetail{
        id
        name
        price
      }
      employee{
        id
        fullName
      }
      user{
        id
        fullName
      }
    }
  }
}
`;

const DELETE_BOOKING= gql`
  mutation deleteBookings($data:ID!){
    deleteBookings(data:$data){
        status
        message
      }
    }
    `;

    const FILTER_UPCOMING_BOOKING_LIST= gql`
  mutation getUpcomingBookingsFilter($data:String!){
    getUpcomingBookingsFilter(data:$data){
        status
        message
        booking{
          id
          price
          totalPrice
          address
          status
          bookingDate
          timeSlot
          totalTime
          paymentType
          orderDetail{
            id
            name
            price
          }
          employee{
            id
            fullName
          }
          user{
            id
            fullName
          }
        }
      }
    }
  `;
const BookingTable = ({
  data,
  isUpcomingBooking,
  setIsUpcomingBooking,
  setEditBookingForm,
  setEditingData,
}) => {

  const { loading, error, data:bookingData } = useQuery(UPCOMING_BOOKING_LIST);
  const { loading:previousLoading, error:previousError, refetch } = useQuery(PREVIOUS_BOOKING_LIST);
  const [deleteBookings, {loading: deleteLoading,error: deletError }] = useMutation(DELETE_BOOKING);
  const [getUpcomingBookingsFilter, {data:dashboardData, loading: prodDeletLoading, error:producterror }] = useMutation(FILTER_UPCOMING_BOOKING_LIST);
  const [dataList, setDataList] = useState(data);
  const [isFetched, setIsFetched] = useState(false);
  const [filterValue,setFilterValue] = useState('');
  const handleEditClick = (row) => {
    setEditBookingForm(true);
    setEditingData(row);
  };

  useEffect(()=>{
    try{
      console.log('bookingList is');
    console.log(bookingData);
    setDataList(bookingData.getUpcomingbooking.booking);
    // setServiceList(data.getAdminService.services)
    setIsFetched(true);
    }catch(err){
      console.log(err);
    }
    
  },[bookingData])

const onUpcomingBookingChange=(e)=>{
  e.preventDefault();
  setIsUpcomingBooking(true)
  setDataList(bookingData.getUpcomingbooking.booking);
}

const onPreviousBookingChange=(e)=>{
  e.preventDefault();
  setIsUpcomingBooking(false)

  refetch().then((res)=>{
    console.log('onPreviousBookingChange hitted');
    console.log(res);
    setIsFetched(false);
    if(res.data.getPreviousbooking.status === "SUCCESS"){
    setDataList(res.data.getPreviousbooking.booking);
    setIsFetched(true);
    }
  }).catch(err=>{
    console.log(err);
  })
}

const handleRemoveRow =(dataId)=> {
  deleteBookings({ 
    variables:{ data: dataId}
  }).then((res)=>{
    // window
    console.log(' delete res is');
    console.log(res);
    if(res.data.deleteBookings.status === "SUCCESS"){
      window.location.reload();
    }
  })
};

const onFilterChange=(e)=>{
  setIsFetched(false);
  setFilterValue(e.target.value);
  getUpcomingBookingsFilter({ 
    variables:{ data: e.target.value}
  }).then((res)=>{
    // window
    console.log('new getUpcomingBookingsFilter res is');
    console.log(res);
    if(res.data.getUpcomingBookingsFilter.status === "SUCCESS"){
      setDataList(res.data.getUpcomingBookingsFilter.booking);
    setIsFetched(true);
    }
   
  })
}

  return (
    <div>
    {
      isFetched ?
      (
        <div className="flex flex-col space-y-6">
      <div className="flex text-[#FBB033] items-center font-be-jost-pro text-xl font-bold space-x-3 pt-8 pl-5">
        <p
          className={`${
            isUpcomingBooking ? "bg-white text-[#00395E]" : "text-[#FBB034]"
          }  hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left`}
          onClick={onUpcomingBookingChange}
        >
          Upcoming Bookings
        </p>
        {/* <p
          className={`${
            !isUpcomingBooking ? "bg-white text-[#00395E]" : "text-[#FBB034]"
          }    hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left`}
          onClick={onPreviousBookingChange}
        >
          Previous Bookings
        </p> */}
        <Link
                     to="/admin/previous/booking"
                     className="text-[#FBB034] hover:bg-white hover:text-[#00395E] rounded-sm cursor-pointer text-base px-2 py-1 font-semibold font-be-jost-pro text-center md:text-left"
                  >
                   <span> Previous Bookings</span>
                  </Link>
                  <select
          placeholder="Category*"
          className={`ml-5 px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none`}
          value={filterValue}
          style={{marginLeft:50}}
          onChange={onFilterChange}
          >
             <option value="overall" selected>overall</option>
              <option value="7">7 days</option>
              <option value="30">30 days</option>
              <option value="90">3 months</option>
              <option value="182">6 months</option>
              <option value="365">12 months</option>
             
        </select>
      </div>
      {/* <div className="overflow-x-auto"> */}
      <div>
        {/* <DataTable
      columns={columns}
      data={data}
      fixedHeader
      customStyles={customStyles}
      pagination
    /> */}
        <DataTable tableName="Upcoming-booking">
          <thead>
            <tr>
              <th data-priority="1" className="text-white">
               Booking ID
              </th>
              <th data-priority="1" className="text-white">
                Name
              </th>
              <th data-priority="2" className="text-white">
                Service
              </th>
              <th data-priority="3" className="text-white">
                Assigned Employee
              </th>
              <th data-priority="4" className="text-white">
                Date/Time
              </th>
              <th data-priority="5" className="text-white">
                Amount
              </th>
              <th data-priority="5" className="text-white">
                Status
              </th>
              <th data-priority="6" className="text-white">
                Addresses
              </th>
              <th data-priority="6" className="text-white">
                Payment Status
              </th>
              {isUpcomingBooking && (
                <th data-priority="9" className="text-white">
                  Actions
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {/* <tr> */}
            {dataList.map((product) => {
              // const [hours, minutes] = product.time.split(":");
              // const dateObj = new Date();
              // dateObj.setHours(hours);
              // dateObj.setMinutes(minutes);

              // const formatter = new Intl.DateTimeFormat("en-US", {
              //   hour: "numeric",
              //   minute: "numeric",
              //   hour12: true,
              // });

              // const formattedTime = formatter.format(dateObj);
            //   const date = new Date(product.bookingDate); // Current date

            // const formattedDate = date.toLocaleDateString('en-US', {
            //     month: '2-digit',
            //     day: '2-digit',
            //     year: 'numeric',
            // });

              return (
                <tr className="border-b-2 border-b-[#E0E0E0]" key={product.id}>
                  <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                    {product.bookingId}
                  </td>
                  <td className="text-white w-[10%] px-1 border-b-[1px] border-b-[#E0E0E0]">
                    {product.user.fullName}
                  </td>
                  <td className="w-[8%] border-b-[1px] border-b-[#E0E0E0]">
                    <div className=" flex justify-center w-3/5 space-x-1">
                      {product.orderDetail.map((word) => (
                        <span className="uppercase text-xs font-medium text-black bg-[#FBB033] rounded-2xl px-2 py-1">{word.name}</span>
                      ))}
                    </div>
                  </td>
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{product.employee ? product.employee.fullName : ""}</td>
                  {/* <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{`${product.date} ${formattedTime}`}</td> */}
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{product.bookingDate},{product.timeSlot}</td>
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{`₹  ${product.totalPrice}`}</td>
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{product.status}</td>
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{product.address}</td>
                  <td className="text-white w-[7%] text-center border-b-[1px] border-b-[#E0E0E0]">{product.paymentType}</td>
                  {isUpcomingBooking && (
                    <td className="w-[10%] text-center px-2 border-b-[1px] border-b-[#E0E0E0]">
                      <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center h-full lg:space-x-2">
                        <button
                          className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                          onClick={() => handleEditClick(product)}
                        >
                          Edit
                        </button>
                        <button className="rounded-md bg-white py-2 px-3 text-black text-sm font-medium"
                        onClick={() => handleRemoveRow(product.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </DataTable>
      </div>
    </div>
      )
      :(
        <div className=" h-screen flex" style={{background:'#000000'}}>
       
        
        </div>
      )
    }
    </div>
  );
};

export default BookingTable;
