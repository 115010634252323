import React from "react";
import Footer from "./Components/Footer";

const Cookie = () => {
  return (
    <>
      <div className="w-full  h-auto ">
        <div className="flex flex-col items-center md:justify-around md:items-center w-full h-auto p-10 bg-[#11120F] space-y-4">
          <p className="text-[#FBB034] text-lg font-normal font-be-jost-pro w-full text-center md:text-left">
            Cookies Policy
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            The small text files set by our website and stored on the computer
            or mobile device of our website visitor or user are known as
            cookies. Cookies hold small amounts of data specific to a particular
            user and our website. These cookie files are accessed by either the
            web server that hosts the website of Barbera or the client’s
            computer/smartphone for a range of analytical and functionality
            purposes.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Types of cookies
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            First Party Cookies
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            These are specific to and created by the website of Barbera. In all
            cases on this website, these cookies do not store information of a
            personal or sensitive nature, or anything that makes you
            identifiable to us, personally.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Third-Party Cookies
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            These are created by external service providers who are directly or
            indirectly associated with Barbera whenever you visit our website.
            The data held can only be seen and managed by the third party and
            not by the people here at Barbera. We do not have control over these
            cookies, only how they are integrated into this website.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Why is the use of cookies?
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            We use the cookies to enhance the look, feel and overall
            functionality of our website and app. We also use the cookies to
            gauge the analytical data of our website and at the same time keep
            tabs on the key performance indicators of our website. We use the
            cookies to generate content and services that our clients would find
            relevant. We use cookies to offer our clients advertisements and
            promotional content that would suit their preferences. We also use
            cookies to efficiently market our website and services across the
            internet and the social media handles of our registered customers.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Cookie Regulations
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            To comply with current legislation, a cookie audit has been carried
            out on this website. This website uses several cookies to enhance
            the visitor experience. We believe that there is no reason to be
            unduly concerned over the privacy implications of any of the cookies
            used by this website, which are listed below for your convenience.
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Which type of cookies do we use?
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            This website does not use any cookies for targeted advertising,
            personalising content or to remember your preferences to make the
            site content more relevant to you when you visit the site. The
            cookies we do use do not store information of a personal or
            sensitive nature, or anything that makes you identifiable to us,
            personally.
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            First Party Cookies
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Session ID
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Our web server may create a session cookie which is essential to
            distinguish you from other users whilst on this website. This will
            expire once you leave the site.
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Analytics
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            This website uses Google Analytics to understand how users find and
            use our website, to monitor visitor behaviour and to help us to
            update and improve the website. Google Analytics creates several
            cookies which we do not deem privacy intrusive.
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Third-Party Cookies
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Google Maps
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            This website uses an embedded Google Map to assist our customers in
            locating us. Google Maps creates several cookies which we do not
            deem privacy intrusive.
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Social Media
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            This website may use a range of widely-used third-party social media
            plugins (such as Facebook, Twitter, Instagram, YouTube and LinkedIn)
            to assist in the marketing of our website and business.
          </p>

          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Cookie Consent
          </p>
          <p className="font-be-jost-pro text-white text-base font-normal w-full">
            Where clear and specific consent is required for any cookies set by
            this website, you will be able to manage your preferences, on an
            individual basis, as required. Cookies can also be blocked in their
            entirety by adjusting your web browser settings but please be aware
            that by blocking all cookies set by our website, en masse, the
            overall user experience may be severely diminished and many features
            may not work altogether.
          </p>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default Cookie;
