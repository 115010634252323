import React from "react";

const AboutHelper = ({ isAbout = false }) => {
  return (
    <div className="flex flex-col-reverse md:flex-row items-center justify-around w-full min-h-[60] py-16 px-3 md:px-0 bg-[#202125] ">
      <img
        src="/Assets/homepage-2.png"
        className="w-full md:w-1/2 lg:w-1/3 mt-16 md:mt-0"
      />
      <div className="flex flex-col items-start justify-center w-full md:w-1/3 space-y-6">
        <p className="text-[#FBB034] text-lg font-normal font-be-jost-pro w-full text-center md:text-left">
          About Us
        </p>
        <p className="font-be-jost-profont-normal text-base text-[#9A9A9A]">
        Welcome to Barbera, a prominent name in India's unisex home salon services sector. Our commitment to quality ensures impeccable salon services delivered to your doorstep at affordable rates. 
        <br></br>Established in August 2020, Barbera presently offers home salon services exclusively in Jaipur, Rajasthan. 
        <br></br>To enhance your convenience, we provide an intuitive iOS and Android app, available on all app stores. 
        <br></br>Since inception, we have proudly served over 25,000 satisfied patrons in Jaipur. 
        <br></br>Join us for a delightful salon experience within the comfort of your home.
        </p>
        <a href="/about" className="bg-[#FBB034] w-full lg:w-2/5 py-3 rounded-md font-bold text-center">
        <button >
          {isAbout ? "Book An Appointment" : "Learn More"}
        </button>
        </a>
      </div>
    </div>
  );
};

export default AboutHelper;
