import { isConstValueNode } from "graphql";
import React, { useState,useEffect } from "react";
import "../../index.css";
import { useQuery, gql,useMutation } from "@apollo/client";

const EMPLOYEE_LIST=gql`
query{
  getEmployeelist{
    status
    message
    employee{
      id
      fullName
    }
  }
}
`;

const EDIT_BOOKING = gql`
mutation editBooking($data:editBookingInput!){
  editBooking(data:$data){
    status
    message
  }
}`;
const BookingForm = ({ setEditBookingForm, editingData }) => {
  console.log('BookingForm');
  console.log(editingData);
  let bookingServices=[];

 let dataObj= editingData.orderDetail.map(item=>{
  bookingServices.push(item.name);
 })
 console.log(bookingServices.join(','));
  const { loading, error, data:empData } = useQuery(EMPLOYEE_LIST);
  const [editBooking, {loading:addLoading,error: addError }] = useMutation(EDIT_BOOKING);
  const [name, setName] = useState(editingData.user.fullName);
  const [services, setServices] = useState(bookingServices.join(','));
  const [assignedEmployee, setAssignedEmployee] = useState(editingData.employee?editingData.employee.id:"");
  const [date, setDate] = useState(editingData.bookingDate);
  const [time, setTime] = useState(editingData.timeSlot);
  const [amount, setAmount] = useState(editingData.price);
  const [address, setAddress] = useState(editingData.address);
  const [dataList, setDataList] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(()=>{
    try{
      console.log('empData is');
    console.log(empData);
    setDataList(empData.getEmployeelist.employee);
    
    setIsFetched(true);
    }catch(err){
      console.log(err);
    }
    
  },[empData])

  const handleUpdate = (e) => {
    // console.log(date, time);
    // setEditBookingForm(false);
    e.preventDefault();
    let finalDataObj={};
    finalDataObj.id= editingData.id;
    finalDataObj.employeeId= assignedEmployee;
    finalDataObj.bookingDate=new Date(date);
    finalDataObj.timeSlot= time;
    console.log(finalDataObj);
    editBooking({ 
      variables:{  
        data: finalDataObj
      }
    }).then((res)=>{
      console.log('editBooking res is');
      console.log(res);
      if(res.data.editBooking.status === "SUCCESS"){
        window.location.reload();
       
      }
    }).catch(err=>{
      console.log(err);
    })
  };

  return (
    <>
    {
      isFetched &&
      (
        <div className="flex flex-col space-y-6">
          <h3 className="text-[#FBB033] font-be-jost-pro font-bold text-2xl">
        Edit Booking
      </h3>
      <div className="flex w-full items-center">
        <span className="font-be-jost-pro font-medium text-white text-sm w-1/6">
          Name:
        </span>
        <input
          type="text"
          placeholder="Name*"
          className="px-2 text-[#808080] py-1 bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none w-4/6"
          value={name}
          disabled
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="flex w-full items-center">
        <span className="font-be-jost-pro font-medium text-white text-sm w-1/6">
          Services:
        </span>
        <input
          type="text"
          placeholder="Services*"
          className="px-2 py-1 text-[#808080] bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none w-4/6"
          value={services}
          onChange={(e) => setServices(e.target.value)}
          disabled
        />
      </div>
      <div className="flex w-full items-center">
        <span className="font-be-jost-pro font-medium text-white text-sm w-1/6">
          Assigned Employee:
        </span>
        <select
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-4/6`}
          value={assignedEmployee}
          onChange={(e) => setAssignedEmployee(e.target.value)}
        >
          <option value="" disabled hidden>
            Assigned Employee*
          </option>
          {/* <option value="Employee No. 1">Emloyee No. 1</option>
          <option value="Employee No. 2">Emloyee No. 2</option>
          <option value="Employee No. 3">Emloyee No. 3</option>
          <option value="Employee No. 4">Emloyee No. 4</option>
          <option value="Employee No. 5">Emloyee No. 5</option>
          <option value="Yet to assigned">Yet to assigned</option> */}
          {
            dataList.map((item)=>(
              <option value={item.id}>{item.fullName}</option>
            ))
          }
        </select>
      </div>
      <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 w-full lg:items-center">
        <div className="flex w-full lg:w-1/2 items-center">
          <span className="font-be-jost-pro font-medium text-white text-sm w-1/5 lg:w-1/3    ">
            Date:
          </span>
          <input
            type="date"
            placeholder="Start Date"
            className="bg-[#0c0b0b] outline-none text-white border-b-2 py-2 px-3 w-4/6 md:w-2/5"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="flex w-full lg:w-1/2 items-center">
          <span className="font-be-jost-pro font-medium text-white text-sm w-1/5 lg:w-[10%]    ">
            Time:
          </span>
          {/* <input
            type="time"
            placeholder="Start Date"
            className="bg-[#0c0b0b] outline-none text-white border-b-2 py-2 px-3 w-4/6 md:w-[57%]"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          /> */}
          <select
          className={`px-2 py-1 bg-[#0c0b0b] border-b-[1px] border-b-[#E0E0E0] outline-none text-white w-4/6`}
          value={time}
          onChange={(e) => setTime(e.target.value)}
        >
          <option value="" disabled hidden>
            Select Time*
          </option>
          <option value="9:00 Am">9:00 Am</option>
          <option value="9:30 Am">9:30 Am</option>
          <option value="10:00 Am">10:00 Am</option>
          <option value="10:30 Am">10:30 Am</option>
          <option value="11:00 Am">11:00 Am</option>
          <option value="11:30 Am">11:30 Am</option>
        </select>
        </div>
      </div>
      <div className="flex w-full items-center">
        <span className="font-be-jost-pro font-medium text-white text-sm w-1/6">
          Amount:
        </span>
        <span className="text-[#808080]">&#8377;</span>
        <input
          type="text"
          placeholder="Amount*"
          className="px-2 py-1 text-[#808080] bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none w-4/6 lg:w-[20%]"
          value={amount}
          disabled
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      <div className="flex w-full items-center">
        <span className="font-be-jost-pro font-medium text-white text-sm w-1/6">
          Address:
        </span>
        <input
          type="text"
          placeholder="Address*"
          className="px-2 py-1 text-[#808080] bg-[#0c0b0b] border-b-2 border-b-[#E0E0E0] outline-none w-4/6"
          value={address}
          disabled
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div className="flex py-16 w-full justify-center items-center">
        <button
          className="px-5 py-2 bg-[#FBB034] font-poppins text-sm font-bold rounded-md"
          onClick={handleUpdate}
        >
          Update Bookings
        </button>
      </div>
    </div>
      )
    }
    </>
  );
};

export default BookingForm;
